<template>
  <div class="giveaways">
      
      <!-- Active Competitions -->

      <section class="container">
            <div class="row">
              <h1 class="text-center mt-5 mb-5" v-if="listings.length > 0">Giveaways</h1>
              <h1 class="text-center mt-3" v-else>No active giveaways.</h1>

              <CompetitionListing v-for="listing in listings" v-bind:listing="listing" v-bind:key="listing.id"/>
            </div>
      </section>

  </div>

  <Footer />

</template>

<script>
import Api from '../scripts/api'

import Footer from '../components/global/Footer'
import CompetitionListing from '../components/home/CompetitionListing'
export default {
    components: {
        CompetitionListing,
        Footer
    },
    props: {
      account: Object
    },
    data() {
      return {
        listings: []
      }
    },
    async created() {
      var result = await Api.competition.listGiveaways();
      if(result.status === 200) {
        this.listings = await result.json();
      }
      else {
        this.$toast.error('Could not retrieve listings.')
      }
    }
}
</script>
